.priceRow {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.price {
    width: 200px;
}

#pricing p {
    margin-bottom: 0;
}